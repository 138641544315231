import React from "react";
import Donate from "component/Donate/Donate";
import Layout from "component/Layout/Layout";
import Seo from "component/seo";
import SidebarRight from "component/Sidebar/SidebarRight";
import SidebarLeft from "component/Sidebar/SidebarLeft";
import { useSiteUrl } from "utils";

export default function DonatePage({ location }) {
  const canonicalUrl = `${useSiteUrl()}${location.pathname}`;

  return (
    <Layout>
      <Seo title="Ủng hộ blog" titleSample={true} canonicalUrl={canonicalUrl} />
      <SidebarLeft />
      <Donate page={true} />
      <SidebarRight />
    </Layout>
  );
}
