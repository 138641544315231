import React from "react";
import "./Donate.scss";
import InfoHeader from "../InfoHeader/InfoHeader";
import BuyMeACoffee from "../BuyMeACoffee/BuyMeACoffee";

export default function Donate({ page }) {
  return (
    <div className={page ? `page donate-section` : "donate-section"}>
      <div className="wrapper">
        {page ? (
          <>
            <InfoHeader title="Ủng hộ" />

            <p>
              Xin chào, đây là blog <b>Homiedev</b> dành cho Front-end
              developer, nếu bạn cảm thấy bài viết trên blog này hữu ích, và bạn
              muốn blog này tiếp tục phát triển, chất lượng hơn nữa{" "}
              <span role="img" aria-label="code">
                😄
              </span>
              , <br /> Các bạn có thể ủng hộ cho blog này. Số tiền ủng hộ mình
              sẽ dành để duy trì và phát triển cho blog.
            </p>
          </>
        ) : (
          <>
            <h3>
              Hey{" "}
              <span role="img" aria-label="code">
                🎉
              </span>{" "}
              ,
              <br />
              Cảm ơn bạn đã đọc bài viết này.
            </h3>
            <p>
              Nếu thấy bài viết hay và muốn có thêm nhiều bài viết chất lượng
              nữa, các bạn có thể <b>Donate</b> cho blog. Số tiền ủng hộ mình sẽ
              dành để duy trì và phát triển blog này{" "}
              <span role="img" aria-label="code">
                😃
              </span>
              .
            </p>
          </>
        )}
        <p>Các bạn có thể ủng hộ cho blog qua hai hình thức:</p>

        <p>
          <strong>
            1. Buy me a coffee ☕ (Mua tặng mình một tách cà phê):
          </strong>
        </p>
        <p>
          Đây là một ứng dụng an toàn, miễn phí để bạn đọc có thể sử dụng thẻ
          tín dụng (Visa/Master), PayPal, hay Google Pay để ủng hộ cho blog. Mỗi
          “tách cà phê” chỉ có giá $1 (khoảng 24 ngàn đồng) nhưng mang lại lợi
          ích không hề nhỏ cho việc duy trì blog.
        </p>
        <p>
          Bạn chỉ cần nhấn vào nút "Buy me a coffee" dưới đây để ủng hộ
          cho&nbsp;
          <span style={{ color: "var(--color-secondary)" }}>Homiedev</span>.
        </p>
        <BuyMeACoffee />
        <p></p>
        <p>
          <strong>
            2. Chuyển khoản thông thường: Bạn cũng có thể chuyển khoản ở Việt
            Nam với thông tin sau:
          </strong>
        </p>
        <p>
          <i>Ngân hàng:</i>&nbsp;
          <span>
            VietinBank (Ngân hàng Thương mại cổ phần Công Thương Việt Nam)
          </span>
        </p>
        <p>
          <i>Số tài khoản:</i>&nbsp;<span>102870636587</span>
        </p>
        <p>
          <i>Chủ tài khoản:</i>&nbsp;<span>Nguyen Anh Vu</span>
        </p>
        <p className="text-footer">
          <span role="img" aria-label="code">
            🎉
          </span>
          Cảm ơn sự quan tâm và ủng hộ của tất cả các bạn!
        </p>
      </div>
    </div>
  );
}
